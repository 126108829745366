

import {v2Request} from "./v2Request";

/**
 * 方案相关接口
 */
export const rechargeRateV2Api = {

  async getList() {
    return v2Request.get(`/rechargeRate/getList`);
  }

};
