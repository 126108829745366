<template>
  <div class="pager" v-if="$store.state.caseDetailPagerVisible">
    <div class="pager-close" @click="$store.commit('HIDE_DETAIL_PAGER')">
      <van-icon name="cross"/>
    </div>
    <div v-if="!pager.scrolling" class="pager" @click="backTop">
      <van-icon name="back-top"/>
    </div>
    <template v-else>
      <div class="pager-item curr">{{ pager.curr }}</div>
      <div class="pager-item">{{ total }}</div>
    </template>
  </div>
</template>

<script>
import {sleep} from "../config/util";

export default {
  props: ['total', 'scrollDomId', 'offsetTop', 'itemDomSelector'],

  data() {
    return {
      pager: {
        scrolling: false,
        curr: '',
      }
    }
  },

  async mounted() {
    const interval = setInterval(() => {
      const dom = document.querySelector(`#${this.scrollDomId}`)
      if (dom) {
        clearInterval(interval)
      }
      dom.addEventListener('scroll', this.onScroll)
    }, 200)
  },


  beforeDestroy() {
    const dom = document.querySelector(`#${this.scrollDomId}`)
    if (dom) {
      dom.removeEventListener('scroll', this.onScroll)
    }
  },


  methods: {

    // 回顶
    backTop() {
      const dom = document.querySelector(`#${this.scrollDomId}`)
      if (dom) {
        dom.scrollTo({top: 0, behavior: 'smooth'})
      }
    },


    //
    onScroll(e) {
      if (!this.pager.scrolling) {
        this.pager.scrolling = true
        setTimeout(() => {
          this.pager.scrolling = false
        }, 2000)
      }

      const elems = document.querySelectorAll(this.itemDomSelector)
      let curr = 0
      for (const elem of elems) {
        const rect = elem.getBoundingClientRect()
        if (rect.top < this.offsetTop) {
          curr++
          continue
        }
        break
      }

      curr = Math.max(curr, 1)
      curr = Math.min(curr, this.total)
      if (this.pager.curr !== curr) {
        this.pager.curr = curr
      }
    }

  }
}
</script>

<style scoped lang="less">

.pager {
  position: fixed;
  right: 10px;
  bottom: 110px;
  height: 45px;
  width: 45px;
  border-radius: 1000px;
  background: #fff;
  border: 1px solid #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  z-index: 1000;
}

.pager-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 1;
  width: 100%;
}

.pager-item.curr {
  border-bottom: 1px solid #e9e9e9;
}

.pager-close {
  position: absolute;
  right: -4px;
  top: -4px;
  color: #fff;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  z-index: 1002;
  border-radius: 100px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
    opacity: .3;
    border-radius: 100px;
  }
}

</style>
