<template>
  <div class="plan-skeleton skeleton-block">

  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">

.plan-skeleton{
  width: calc(100vw - 56px);
  height: calc((100vw - 56px) * 308.88 / 330.06);
  margin: 20px auto 0 auto;
}

</style>
