import {v2Request} from "./v2Request";


export const visitorTokenV2Api = {

  create(apiCode){
    return v2Request.get(`search-svr/visitorToken/create`,{params:{apiCode}})
  }

}
