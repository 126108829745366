<template>
  <div class="case-detail" v-if="Object.keys(info).length">
    <scroll-pager scroll-dom-id="planListDetailContent" :offset-top="styles.headerHeight" item-dom-selector=".case-item"
                  :total="info.planNum"/>
    <v-header ref="header" :title="title"></v-header>
    <div class="content" id="planListDetailContent">
      <van-pull-refresh v-model="loading" @refresh="getInfo">
        <div>
          <div class="cover-group">
            <img class="cover" :src="info.img"/>
            <!--<div class="full-group" @click="$router.push('/preview/'+info.id)">
                <img src="https://cdn.001ppt.cn/h5/assets/images/big.png" class="full"/>
            </div>-->
          </div>

          <div class="detail-group">
            <span class="title">{{ info.title }}</span>
            <div class="tags">
              <template v-for="i in setTag(info.tags)">
                <span @click="search(i)">{{ i }}</span>
              </template>
            </div>

            <div class="filt-type">
              <span><span style="font-weight: bold;">文件格式：</span>ZIP</span>
              <span><span
                style="font-weight: bold;">文件大小：</span>{{ setFileSize(info.uploadResourceFileSize, 'G') }}</span>
              <span v-if="info.isShowDownload == 'Y' && info.downloadNum>0"><span
                style="font-weight: bold;">已下载数：</span> {{ info.downloadNum || 0 }}</span>
              <span style="font-weight: bolder">
                更新时间：<span @click="$refs.planListLog.show()"
                               class="tip_txt_link">{{ info.addRefPlanListTime | date }}</span>
              </span>
            </div>
          </div>


          <!--合集包-->
          <div>
            <div class="list-title" :style="{paddingBottom: columns && columns.length>1?'':'12px'}">
              <span>本合集包含（{{ info.planNum }}份方案）</span>
              <div class="btn-group">
                <i class="iconfont icon-arrow" @click="$router.push(`/caseGroup/${$route.params.id}`)"></i>
              </div>
            </div>

            <van-sticky :offset-top="styles.headerHeight - 1" style="height: 0;overflow: hidden;margin-bottom: 12px"
                        @change="styles.fixed=$event" v-if="columns && columns.length > 1">
              <div class="column-tab" :style="{background: styles.fixed?'#fff':'',}">
                <div class="columns" v-if="browseMode==='normal'">
                  <div class="column-item" @click="goColumn(item)" :key="item.id" v-for="item in columns"
                       v-if="item && item.planCount"
                       :class="{active: currColumnId===item.id}">{{ item.name }}
                  </div>
                </div>
                <div class="columns" v-else-if="browseMode==='popover'">
                  <div class="column-item" @click="goColumn(item)" :key="item.id" v-for="item in columns"
                       v-if="item && item.planCount"
                       :class="{active: currColumnId===item.id}">{{ item.name }}
                  </div>
                </div>

                <div class="mode-btn" v-if="styles.fixed" @click="switchBrowseMode">
                  <van-icon name="wap-nav"/>
                </div>
              </div>

              <!--              <div class="column-tab" v-if="styles.sticky && browseMode === 'popover">-->
              <!--                -->
              <!--                -->
              <!--              </div>-->
            </van-sticky>


            <div class="list-body" v-if="columns && columns.length">

              <div v-for="col in columns" :key="col.id" class="list-body-item">
                <div class="column-title" :id="`columnTitle-${col.id}`"
                     v-if="columns && columns.length > 1 && col.planCount">{{ col.name }}
                </div>
                <div :id="`columnPlans-${col.id}`">
                  <template v-if="col.plans && col.plans.length">
                    <plan-card v-for="item in col.plans" :key="item.id" class="case-item" :detail="item"
                            @handle-click="clickItem"></plan-card>
                  </template>
                  <template v-else>
                    <plan-skeleton v-for="i in col.planCount" :key="i"/>
                  </template>
                </div>
              </div>
            </div>

            <div class="list-body" v-else>
              <div class=" plan-item skeleton-block" v-for="i in 3"></div>
            </div>


          </div>
          <div class="copyright">
            <span>© 方案版权归上传用户解释</span>
            <router-link to="/about/copyright" class="copyright-link">
              版权声明
              <van-icon name="arrow"/>
            </router-link>
          </div>


        </div>
      </van-pull-refresh>
      <plan-list-log ref="planListLog" :plan-list="info"/>

      <van-popup lazy-render position="top" :value="browseMode==='popover'" round @close="browseMode='normal'"
                 @click-overlay="browseMode='normal'">
        <div class="column-popup">
          <div class="column-pop-item" v-if="item && item.planCount" :class="{active: currColumnId===item.id}"
               @click="goColumn(item)" v-for="item in columns">{{ item.name }}
          </div>
        </div>
      </van-popup>

    </div>
    <operation :info="info" @operation="operation" page="group" @unlock="getInfo"
               @action="onOperationAction"></operation>
  </div>
  <plan-list-skeleton v-else/>

</template>

<script>
import vCase from '@/components/case'
import avatar from '@/components/avatar'
import {scheme, user} from '@/api'

import operation from './components/planListOperation'
import {mapGetters} from 'vuex'
import {getElementPos, sleep} from "../../config/util";
import {nav} from "@/config/nav";
import {planListV2Api} from "../../api/v2/planListV2Api";
import PlanListSkeleton from "./components/planListSkeleton";
import ScrollPager from "../../components/scrollPager";
import PlanSkeleton from "../../components/biz/PlanSkeleton";
import {syncronized} from "../../config/decorators/syncronized";
import PlanListLog from "./components/planListLog";
import PlanCard from "../../components/planCard";

export default {
  components: {PlanCard, PlanListLog, PlanSkeleton, ScrollPager, PlanListSkeleton, vCase, operation, avatar},
  computed: {
    ...mapGetters(['userInfo', 'userVip']),
    info() {
      return this.$store.state.planListDetail.planLists.find(p => p.id === this.$route.params.id) || {}
    },

    columns() {
      return (this.$store.state.planListDetail.columnsList.find(c => c.planListId === this.$route.params.id) || {columns: []}).columns
    },

    currTabColumn() {
      const col = this.columns.find(c => c.id === this.tabColumnId) || {}
      return col || {}
    },

    currTabPlans() {
      const col = this.columns.find(c => c.id === this.tabColumnId) || {}
      return col.plans || []
    }

  },
  data() {
    return {
      title: '',
      loading: false,
      styles: {
        headerHeight: '',
        fixed: false
      },
      currColumnId: '',
      colProms: {},
      browseMode: 'normal',
      tabColumnId: '',
      preventOnScroll: false
    }
  },


  async mounted() {
    const from = this.$route.meta.$from.path
    await this.getInfo();
    if (from.startsWith('/caseDetail/') || from.startsWith('/caseGroup')) {
      const $content = document.querySelector('#planListDetailContent')
      const scrollTopObj = this.$store.state.planListDetail.scrollTops.find(s => s.planListId === this.$route.params.id)
      $content.scrollTop = scrollTopObj ? (scrollTopObj.scrollTop || 0) : 0
    }
    this.initStyles()
    await this.loadColumns()
    if (this.info.isAutoShowVersion && !this.$store.state.planListDetail.hasShowVersionPlanListIds.includes(this.info.id)) {
      this.$store.commit('planListDetail/ADD_HAS_SHOW_VERSION_PLAN_LIST_ID', this.info.id)
      this.$refs.planListLog.show()
    }
  },


  beforeDestroy() {
    const $content = document.querySelector('#planListDetailContent')
    if ($content) {
      $content.removeEventListener('scroll', this.onScroll)
    }
    document.querySelector('header').style.zIndex = ''
  },

  methods: {

    chooseTabColumn(item) {
      this.tabColumnId = item.id
      if (!this.currTabColumn.plans || !this.currTabColumn.plans.length) {
        this.loadColumnPlans(this.currTabColumn)
      }
    },


    switchBrowseMode() {
      this.browseMode = this.browseMode === 'normal' ? 'popover' : 'normal'
      if (!this.tabColumnId) {
        this.tabColumnId = this.columns[0].id
      }
    },

    async tryLoadColumnPlans() {
      for (const col of this.columns) {
        if (col && col.plans && col.plans.length) {
          continue
        }
        const columnTitleDom = document.querySelector(`#columnPlans-${col.id}`)
        if (!columnTitleDom) {
          continue
        }
        const rect = columnTitleDom.getBoundingClientRect()
        if (rect.top < document.documentElement.clientHeight) {
          this.loadColumnPlans(col).then()
        }
      }
    },


    async loadColumnPlans(col) {
      let colProm = this.colProms[col.id]
      if (!colProm) {
        colProm = planListV2Api.findColumnPlans(this.info.id, col.id)
        this.colProms[col.id] = colProm
      }
      col.plans = await colProm

      // this.$store.commit('planListDetail/UPDATE_COLUMN',{planListId: col.planListId, column: col})
    },


    async loadColumns() {
      await sleep(500)
      const $content = document.querySelector('#planListDetailContent')
      if ($content) {
        $content.removeEventListener('scroll', this.onScroll)
        $content.addEventListener('scroll', this.onScroll)
      }
      await this.tryLoadColumnPlans()
    },


    async goColumn(col) {
      this.browseMode = 'normal'
      const columnTitle = document.querySelector('#columnTitle-' + col.id)
      const content = document.querySelector('.cover-group')
      const stickyBottom = document.querySelector('.columns').getBoundingClientRect().height + this.styles.headerHeight
      const top = columnTitle.getBoundingClientRect().top - content.getBoundingClientRect().top - stickyBottom
      this.preventOnScroll = true
      document.querySelector('#planListDetailContent').scrollTop = top
      this.currColumnId = col.id
      if (!col.plans || !col.plans.length) {
        await this.loadColumnPlans(col)
      }
      this.preventOnScroll = false
    },

    onScroll(e) {
      if (this.preventOnScroll) {
        return
      }
      this.tryLoadColumnPlans()
      this.$store.commit('planListDetail/UPDATE_SCROLL_TOP', {
        planListId: this.$route.params.id,
        scrollTop: e.target.scrollTop
      })
      if (!this.columns || this.columns.length <= 1) {
        return
      }
      const titles = document.querySelectorAll('.column-title')
      const stickyBottom = document.querySelector('.columns').getBoundingClientRect().bottom
      for (let i = titles.length - 1; i >= 0; i--) {
        const title = titles[i]
        const rect = title.getBoundingClientRect()
        if (rect.top <= stickyBottom + 60) {
          this.currColumnId = this.columns.filter(c => c.planCount > 0)[i].id
          break
        }
      }
    },


    async initStyles() {
      await sleep(500)
      let header = null
      let retryTimes = 0
      while (!header) {
        retryTimes++
        if (retryTimes > 10) {
          break
        }
        if (this.$refs.header) {
          header = this.$refs.header.$el.getBoundingClientRect()
          break
        }
        await sleep(200)
      }
      this.styles.headerHeight = header.bottom
      document.querySelector('header').style.zIndex = '10000000'
    },


    //搜索
    search(keyword) {
      let url = `${window.location.origin}/home?keyword=${keyword}`;
      window.location.href = url;
    },

    async getInfo() {
      await this.$store.dispatch('planListDetail/loadPlanList', {id: this.$route.params.id})
    },


    //去用户主页
    goUserHome() {
      if (this.info.createId == this.userInfo.id) {
        this.$router.replace('/mine');
        return;
      }
      this.$router.push(`/otherPerson/${this.info.createId}`);
    },

    operation() {
      this.getInfo();
    },

    //关注用户
    async focusUser(id) {
      const {code, msg} = await user.focus({toUserId: id, userId: this.userInfo.id});
      if (code != 1) return this.$toast.info(msg);
      this.$toast.success(msg);
      this.getInfo();
    },


    //取消关注
    async cancelFocus(id) {
      const {code} = await user.cancelFocus(id);
      if (code != 1) return;
      this.getInfo();
    },

    clickItem(item) {
      nav.goPlanDetail(item.plan.id)
    },


    onOperationAction(e) {
      const {action} = e
      if (action === 'collect') {
        this.info.clickCollect = !this.info.clickCollect
      } else if (action === 'recommend') {
        this.info.clickRecommend = !this.info.clickRecommend
      } else if (action === 'unlock') {
        this.info.pay = true
      }
    }

  }
}
</script>

<style scoped lang="less">
.case-detail {
  background-color: var(--page-color);
  box-sizing: border-box;

}

.content {
  background-color: var(--page-color);
  height: calc(100vh - 60px - var(--header-height));
  overflow-y: auto;
  margin-top: var(--header-height);
}

.cover-group {
  position: relative;
  height: 211px;
}

.cover {
  width: 100%;
  height: 100%;
}

.detail-group {
  background-color: #fff;
  padding: 12px 28px;
}

.title {
  font-size: 16px;
  font-weight: bold;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.tags span {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(21, 29, 54, 0.05);
  border-radius: 20px;
  min-width: 70px;
  padding: 0 5px;
  height: 20px;
  margin-top: 9px;
  color: #999;
  font-size: 12px;
  flex-shrink: 0;
  margin-right: 10px;
}

//.tags span:nth-child(4n+3) {
//  margin-left: 10px;
//  margin-right: 10px;
//}
//
//.tags span:nth-child(4n+1) {
//  margin-left: 10px;
//  margin-right: 10px;
//}

.tags span:nth-child(1) {
  margin-left: 0;
}


.full-group {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: rgba(255, 255, 255, .01);
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 20px;
  right: 20px;
  border-radius: 100%;
}

.full-group .full {
  width: 20px;
}

.filt-type {
  margin-top: 14px;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  line-height: 18px;
}

.filt-type span {
  font-size: 10px;
}

.user-group {

  display: flex;
  align-items: center;
  justify-content: space-between;

}


.user-group .avatar {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  margin-right: 13px;
}

.user-info {
  flex: 1;
  display: flex;
  align-items: center;
}

.user-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.name {
  font-weight: bold;
}

.desc {
  margin-top: 2px;
  font-size: 10px;
  opacity: .3;
}

.user-group .btn {
  background-color: var(--text-color);
  color: #fff;
  width: 70px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
}

.list-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 29px 0 29px;
}

.btn-group {
  display: flex;
  align-items: center;
}

.list-title span {
  font-size: 12px;
}

.btn-group span {
  opacity: 0.3;
  font-size: 12px;
}

.list-title .refresh {
  font-size: 15px;
  opacity: .3;
  margin-left: 5px;
  margin-top: 1px;
}

.list-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 28px 15px 28px;
}

.list-body-item {
  margin-bottom: 24px;
}

.column-title {
  font-size: 12px;
  font-weight: bolder;
  margin-bottom: 12px;
}

.case-item {
  width: calc(100vw - 56px);
}

.case-item:nth-child(n+2) {
  margin-top: 20px;
}

.copyright {
  margin-bottom: 18px;
  margin-top: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 12px;
    color: #999;
    transform: scale(.8);
  }

  .copyright-link {
    font-size: 12px;
    color: #71adf9;
    transform: scale(.8);
  }
}

.columns {
  display: flex;
  align-items: center;
  width: 100vw;
  overflow-x: auto;
  padding: 12px 27px;
  flex-grow: 1;
}

.column-item {
  font-size: 12px;
  color: #999;
  margin-right: 12px;
  flex-shrink: 0;
}

.column-item.active {
  color: #151D36;
  font-weight: bolder;
}

.plan-item {
  width: calc(100vw - 56px);
  height: calc((100vw - 56px) * 17 / 16);
  margin: 8px auto 0 auto;
}

.column-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
}

.mode-btn {
  padding: 12px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.column-popup {
  padding: 100px 27px 27px 27px;
  max-height: 60vh;
  overflow: scroll;
  background: #fff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.column-pop-item {
  padding: 4px 8px;
  font-size: 12px;
  color: #999;
}

.column-pop-item.active {
  color: #151d36;
  font-weight: bolder;
}


</style>

<style>
.case-detail .van-sticky--fixed {
  z-index: 100000;
}
</style>
