<template>
  <div>
    <v-header title=" " :useHolder="true"/>
    <div class="cover skeleton-block"></div>
    <div class="detail-group">
      <div class="title skeleton-block"></div>
      <div class="tags">
        <div class="tag-item skeleton-block" v-for="i in 3" :key="i"></div>
      </div>
      <div class="info">
        <div class="info-item skeleton-block" style="width: 30vw"></div>
        <div class="info-item skeleton-block"></div>
      </div>
    </div>

    <div class="plans">
      <div class="plans-title">
        <div class="plans-title-item skeleton-block"></div>
        <div class="plans-title-item skeleton-block"></div>
      </div>
      <div class="plan-item skeleton-block" v-for="i in 3" :key="i"></div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="less">
.cover {
  width: 100vw;
  height: calc(100vw * 233 / 414);
}

.detail-group{
  padding: 12px 28px;
  background: #fff;
}

.title {
  width: 100%;
  height: 32px;
}

.tags{
  display: flex;
  align-items: center;
  padding: 8px 0 ;
}

.tag-item{
  height: 20px;
  width: 60px;
  margin-right: 12px;
  border-radius: 100px;
}

.info-item{
  width: 40vw;
  height: 24px;
  margin-bottom: 8px;
}

.plans{
  background: #f4f4f4;
  padding: 28px 0;
}

.plans-title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 28px;
}

.plans-title-item{
  height: 20px;
  width: 60px;
}

.plan-item{
  width: calc(100vw - 56px);
  height: calc((100vw - 56px) * 17 / 16);
  margin: 8px auto 0 auto;
}

</style>
