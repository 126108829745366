<template>
  <van-popup position="bottom" v-model="visible" round closeable>
    <div class="plan-list-log">
      <div class="header">
        <div class="title">{{ planList.title }}</div>
        <div class="last-add-ref-time">最后更新:
          <van-icon name="clock-o" style="margin: 4px 0 3px 2px"/>
          {{ planList.addRefPlanListTime | date }}
        </div>
      </div>

      <div class="ref-item-list">
        <van-list @load="loadMore(planPage.current+1)" :finished="planPage.current*planPage.size >= planPage.total" :value="loadings.loadPlanPage">
          <div @click="$router.push('/caseDetail/'+item.plan.id)" class="ref-item" v-for="item in planPage.records">
            <div class="time">
              <van-icon name="clock-o" style="margin-right: 4px"/>
              {{ item.addRefPlanListTime| date }} 收录了
            </div>
            <div class="plan-title">{{ item.plan.planTitle }}</div>
          </div>
        </van-list>
      </div>
    </div>
  </van-popup>
</template>

<script>
import {planListV2Api} from "../../../api/v2/planListV2Api";
import {sleep} from "../../../config/util";

export default {
  props: ['planList'],
  data() {
    return {
      visible: false,
      planPage: {
        current: 0,
        size: 10,
        total: 100,
        records: []
      },
      loadings: {
        loadPlanPage: false
      }
    }
  },

  methods: {
    show() {
      this.visible = true
    },

    hide() {
      this.visible = false
    },


    async loadMore(current) {
      try {
        if (this.loadings.loadPlanPage) {
          return
        }
        this.loadings.loadPlanPage = true
        current = current || this.planPage.current
        const res = await planListV2Api.findPlanPageByPlanListId({
          planListId: this.planList.id,
          current,
          size: this.planPage.size,
          pageSize: this.planPage.size
        })

        for (const record of res.records) {
          const exists = this.planPage.records.some(r => r.plan.id === record.plan.id)
          if (!exists) {
            this.planPage.records.push(record)
          }
        }

        this.planPage.current = res.current
        this.planPage.total = res.total
      } finally {
        await sleep(300)
        this.loadings.loadPlanPage = false
      }
    },

  }
}
</script>

<style scoped lang="less">
.plan-list-log {
  height: 80vh;
  background-color: #f2f5f7;
  position: relative;
  overflow-y: hidden;
}

.plan-list-log .header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.plan-list-log .header .last-add-ref-time {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: #999;
  font-size: 12px;
  margin-bottom: 12px;
}

.plan-list-log .header .title {
  font-size: 14px;
  width: 300px;
  text-align: center;
  padding: 12px 0 8px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bolder;
}


.plan-list-log .ref-item-list {
  height: calc(80vh - 80px);
  overflow-y: auto;
  margin-top: 80px;
  background-color: #fff;
  border-top: 1px solid #f2f5f7;
}

.plan-list-log .ref-item {
  margin: 12px;
  padding: 12px;
  background-color: #fff;
  border-radius: 6px;
}

.plan-list-log .ref-item .time{
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #999;
}

.plan-list-log .ref-item .plan-title{
  padding-top: 4px;
  font-size: 12px;
}

</style>
