<template>
  <model ref="model">
    <div style="position: relative">
      <div class="unlock-dialog" :class="{'is-safari':isSafari}" v-if="!isWechatEnv">
        <i class="iconfont icon-success success-icon"></i>
        <div class="title">已解锁文件完整版</div>
<!--        <div class="desc" style="color: #151D36">如何下载到手机？-->
<!--          <div style="opacity: .3" @click="toggleExpand">-->
<!--            <template v-if="!visibles.expand">-->
<!--              <van-icon name="arrow-down"/>-->
<!--              展开-->
<!--            </template>-->
<!--            <template v-else>-->
<!--              <van-icon name="arrow-up"/>-->
<!--              折叠-->
<!--            </template>-->
<!--          </div>-->
<!--        </div>-->

        <div class="copy-step-list">
          <div class="go-browser-guild" v-if="hasCopy" style="right: 150px;">
            <div class="tip">黏贴文件地址到搜索框</div>
            <img src="https://cdn.001ppt.cn/wxApp/assets/images/go-sys-browser-guild.png" alt="">
          </div>
          <div class="copy-step">
            <div class="title">下载到手机？</div>
            <van-icon class="icon" name="arrow"/>
            <div class="handle" style="padding-left: 14px">
              <a :href="url" class="copy-btn">点击前往</a>
<!--              <div class="has-copy-btn" v-else @click="copyDownloadUrl">已复制</div>-->
            </div>
          </div>
<!--          <div class="copy-step">-->
<!--            <div class="title">② 黏贴到搜索框</div>-->
<!--            <van-icon class="icon" name="arrow"/>-->
<!--            <div class="handle">-->
<!--              <img src="https://cdn.001ppt.cn/wxApp/assets/images/copy-step-3.png" alt="">-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>

      <div class="unlock-dialog" v-else>
        <i class="iconfont success-icon icon-success"></i>
        <div class="title">已解锁文件完整版</div>
<!--        <div class="desc" style="color: #151D36">如何下载到手机？-->
<!--          <div style="opacity: .3" @click="toggleExpand">-->
<!--            <template v-if="!visibles.expand">-->
<!--              <van-icon name="arrow-down"/>-->
<!--              展开-->
<!--            </template>-->
<!--            <template v-else>-->
<!--              <van-icon name="arrow-up"/>-->
<!--              折叠-->
<!--            </template>-->
<!--          </div>-->
<!--        </div>-->

        <div class="copy-step-list">
          <div class="go-browser-guild" v-if="hasCopy">
            <div class="tip">点击右上角 <img src="https://cdn.001ppt.cn/wxApp/assets/images/triple-dot.png" alt="">
              跳转至浏览器
            </div>
            <img src="https://cdn.001ppt.cn/wxApp/assets/images/go-sys-browser-guild.png" alt="">
          </div>
          <div class="copy-step">
            <div class="title">下载到手机？</div>
            <van-icon class="icon" name="arrow"/>
            <div class="handle" style="padding-left: 14px">
              <a :href="url" class="copy-btn">点击前往</a>
<!--              <div class="has-copy-btn" v-else @click="copyDownloadUrl">已复制</div>-->
            </div>
          </div>
<!--          <div class="copy-step">-->
<!--            <div class="title" style="transform: translateY(6px)">② 右上角跳转</div>-->
<!--            <van-icon class="icon" name="arrow" style="transform: translateY(6px)"/>-->
<!--            <div class="handle">-->
<!--              <img src="https://cdn.001ppt.cn/wxApp/assets/images/copy-step-2.png" alt="">-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="copy-step">-->
<!--            <div class="title">③ 黏贴到搜索框</div>-->
<!--            <van-icon class="icon" name="arrow"/>-->
<!--            <div class="handle">-->
<!--              <img src="https://cdn.001ppt.cn/wxApp/assets/images/copy-step-3.png" alt="">-->
<!--            </div>-->
          </div>
        </div>
      </div>

      <template v-if="loading">
        <div class="unlock-dialog"
             style="position: absolute;z-index: 10000;left: 0;right: 0;top: 0;bottom: 0;background: #fff">
          <i class="skeleton-block success-icon" style="margin-top: 14px"></i>

<!--          <div class="title skeleton-block" style="margin: 0 auto;width: 80%"><span style="visibility: hidden">已解锁文件完整版</span></div>-->
          <div class="skeleton-block" style="width: 50%;margin:14px auto 14px auto;height: 30px"></div>

          <template>
            <div style="display: flex;align-items: center;padding: 12px 20px 0 20px;justify-content: space-between"
                 v-for="i in 1">
              <div class="skeleton-block" style="height: 30px;width: 100px"></div>
              <div class="skeleton-block" style="height: 30px;width: 180px"></div>
            </div>
          </template>
        </div>
      </template>

  </model>
</template>


------------------ script ------------------
<script>
import Model from "../../../components/model/model";
import {mapGetters, mapMutations} from "vuex";
import {copyToClipBoard} from "../../../config/util";
import {visitorTokenV2Api} from "../../../api/v2/visitorTokenV2Api";

export default {
  components: {Model},
  computed: {
    ...mapGetters(['userInfo']),
    isWechatEnv() {
      return this.getBrowser() === 'wechat';
    },

    isSafari() {
      return navigator.userAgent.includes('iPhone') && (!!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/) || !!navigator.userAgent.match(/Version\/[\d\.]+.*safari/));
    }
  },
  props: {
    planId: {
      type: String
    },
    planListId: {
      type: String
    }
  },


  async created() {
    await this.$store.dispatch('userPreference/load')
    this.visibles.expand = this.$store.state.userPreference.appletPlanDetailUnlockDialogExpand
  },

  data() {
    return {
      loading: false,
      model: {
        email: '',
        verifyCode: ''
      },
      seconds: 0,
      url: '',
      hasCopy: false,
      visibles: {
        expand: false
      }
    }
  },


  methods: {
    ...mapMutations(['SET_USERINFO']),
    async toggleDialog() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 300)
      this.hasCopy = false
      this.$refs.model.toggleDialog();
      const token = await visitorTokenV2Api.create('download')

      if (this.planId) {
        this.url = window.location.origin + '/downloadPlan?id=' + this.planId + '&visitor=' + token
      } else if (this.planListId) {
        this.url = window.location.origin + '/downloadPlanList?id=' + this.planListId + '&visitor=' + token
      }
    },

    // toggleExpand() {
    //   if (this.visibles.expand) {
    //     this.visibles.expand = false
    //     this.visibles.expand = false
    //   } else {
    //     this.visibles.expand = true
    //     this.hasCopy = false
    //   }
    //   this.$store.dispatch('userPreference/save', {appletPlanDetailUnlockDialogExpand: this.visibles.expand})
    // },


    copyDownloadUrl() {
      copyToClipBoard(this.url)
      this.$toast.success('复制成功')
      this.hasCopy = true
    }

  }
}
</script>


------------------ styles ------------------
<style lang="less" scoped>
@import "./unlock";

.success-icon {
  display: block;
  width: 100px;
  margin: 0 auto 20px auto;
  height: 100px;
  font-size: 100px;
  text-align: center;
  border-radius: 100px;
}

.desc {
  color: #999;
  text-align: center;
}

.sub-desc {
  padding: 4px 16px;
  text-align: center;

  .red {
    display: inline-block;
    flex-shrink: 0;
  }
}

.copy-step-list {
  padding-bottom: 12px;
}

.copy-step {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px 0 32px;
  margin-top: 26px;

  .title {
    min-width: 102px;
    font-size: 12px;
    //width: 93px;
    font-weight: normal;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    transform: translateY(-2px);
    //padding-left: 24px;
    padding-right: 14px;
    box-sizing: border-box;
  }

  .icon {
    font-size: 28px;
    font-weight: bolder;
    //flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    //padding-right: 24px;
  }

  .handle {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    .has-copy-btn {
      width: 100px;
      height: 26px;
      color: rgba(21, 29, 54, 1);
      background: #fff;
      border: 1px solid rgba(21, 29, 54, 1);
      border-radius: 13px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      opacity: .3;
    }

    .copy-btn {
      width: 100px;
      height: 26px;
      color: #fff;
      background: #151D36;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      font-size: 12px;
    }

    img {
      width: 100px;
    }
  }
}

.go-browser-guild {
  position: fixed;
  right: 20px;
  top: 13px;
  display: flex;
  align-items: flex-end;
  color: #fff;

  .tip {
    transform: translateY(8px);
    padding-right: 6px;
    font-size: 12px;
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      padding: 0 2px;
      transform: translateY(1px);
    }
  }

  img {
    height: 25px;
    width: 80px;
  }
}

.is-safari .go-browser-guild {
  top: auto;
  bottom: 10px;
  right: auto;
  left: 10px;

  .tip {
    transform: translateY(-16px);
  }

  img {
    transform: rotateX(180deg);
  }
}
</style>
