<template>
  <div>
    <div class="extra">
      <van-share-sheet
        v-model="visibles.share"
        title="立即分享给好友"
        :options="shareOptions"
        @select="selectShare"
      >
        <template #description>
          <div>新用户通过你的分享🔗首次绑定手机号</div>
          <div>
            双方都会获得💰{{ yanzhiFilter(config.recommendAwardYanzhi) }}奖励严值噢(¥{{
              moneyFilter(config.recommendAwardYanzhi / 10)
            }})~邀请人数无上限✌️~
          </div>
        </template>
      </van-share-sheet>
      <download-plan-modal :plan-list-id="info.id" ref="hasUnlockedModel"/>
    </div>


    <model ref="unlockModel">
      <div class="unlock-dialog">

        <!--        会员免费的情况-->
        <template v-if="(userVip.vipRightId === '4' && info.isMemberFree) || userVip.vipRightId === '5'">
          <div class="unlock-dialog-header">
            <div class="vip-badge">
              <img :src="getVipIcon(userVip.vipRightId)">
              <div>VIP专享</div>
            </div>
          </div>
          <div class="title">免费收入囊中</div>
          <div class="rpxBorder"></div>
          <div class="confirm-btn" @click="confirmUnlock">确认解锁下载</div>

          <template v-if="userVip.vipRightId !== '5'">
            <router-link to="/member?id=4" class="tip_txt_link"
                         style="margin-top: 16px;display: flex;align-items: center;justify-content: center"
                         v-if="userVip.vipRightId!=='4' && info.isMemberFree"
            >
              <img style="width: 16px;height: 16px;margin-right: 4px" :src="getVipIcon('4')" alt="">免费解锁了解一下
            </router-link>
            <router-link to="/member?id=5" class="tip_txt_link"
                         style="margin-top: 16px;display: flex;align-items: center;justify-content: center"
                         v-if="userVip.vipRightId!=='4' && !info.isMemberFree"
            >
              <img style="width: 16px;height: 16px;margin-right: 4px" :src="getVipIcon('5')" alt="">免费解锁了解一下
            </router-link>

<!--            <router-link to="/member?id=4" class="tip_txt_link"-->
<!--                         style="margin-top: 16px;display: flex;align-items: center;justify-content: center"-->
<!--                         v-if="userVip.vipRightId==='4' && info.isMemberFree"-->
<!--            >-->
<!--              <img style="width: 16px;height: 16px;margin-right: 4px" :src="getVipIcon('4')" alt="">免费解锁了解一下-->
<!--            </router-link>-->
            <router-link to="/member?id=5" class="tip_txt_link"
                         style="margin-top: 16px;display: flex;align-items: center;justify-content: center"
                         v-if="userVip.vipRightId==='4' && !info.isMemberFree"
            >
              <img style="width: 16px;height: 16px;margin-right: 4px" :src="getVipIcon('5')" alt="">免费解锁了解一下
            </router-link>

          </template>


        </template>


        <!--        付费的情况-->
        <template v-else>
          <div class="unlock-dialog-header" v-if="userVip.caseList<10">
            <div :class="'vip-badge vip-badge-'+userVip.vipRightId">
              <img v-if="getVipIcon(userVip.vipRightId)" :src="getVipIcon(userVip.vipRightId)">
              <div>会员{{ userVip.caseList }}折</div>
            </div>
          </div>
          <div class="title">确认使用
            <div class="red">&nbsp;¥{{ moneyFilter(finalPrice) }}&nbsp;</div>
            解锁
          </div>
          <div class="desc gray">
            严值剩余 {{ yanzhiFilter(wallet.totalRemainValue) }}(¥{{ moneyFilter(wallet.totalRemainValue / 10) }})
          </div>

          <template v-if="wallet.totalRemainValue > finalPrice*10">
            <div class="confirm-btn" @click="confirmUnlock">确认解锁下载</div>
          </template>
          <template v-else>
            <unlock-recharge :need-recharge-money="finalPrice - wallet.totalRemainValue / 10" :default-recharge-money="100" @paySuccess="confirmUnlock"/>
<!--            <div class="confirm-btn" @click="showRecharge">确认充值解锁</div>-->
          </template>

          <div class="desc gray" style="padding-top: 12px">*确认解锁后，严值将自动抵扣</div>

          <template v-if="userVip.vipRightId !== '5'">
            <router-link to="/member?id=4" class="tip_txt_link"
                         style="margin-top: 16px;display: flex;align-items: center;justify-content: center"
                         v-if="userVip.vipRightId!=='4' && info.isMemberFree"
            >
              <img style="width: 16px;height: 16px;margin-right: 4px" :src="getVipIcon('4')" alt="">免费解锁了解一下
            </router-link>
            <router-link to="/member?id=5" class="tip_txt_link"
                         style="margin-top: 16px;display: flex;align-items: center;justify-content: center"
                         v-if="userVip.vipRightId!=='4' && !info.isMemberFree"
            >
              <img style="width: 16px;height: 16px;margin-right: 4px" :src="getVipIcon('5')" alt="">免费解锁了解一下
            </router-link>

<!--            <router-link to="/member?id=4" class="tip_txt_link"-->
<!--                         style="margin-top: 16px;display: flex;align-items: center;justify-content: center"-->
<!--                         v-if="userVip.vipRightId==='4' && info.isMemberFree"-->
<!--            >-->
<!--              <img style="width: 16px;height: 16px;margin-right: 4px" :src="getVipIcon('4')" alt="">免费解锁了解一下-->
<!--            </router-link>-->
            <router-link to="/member?id=5" class="tip_txt_link"
                         style="margin-top: 16px;display: flex;align-items: center;justify-content: center"
                         v-if="userVip.vipRightId==='4' && !info.isMemberFree"
            >
              <img style="width: 16px;height: 16px;margin-right: 4px" :src="getVipIcon('5')" alt="">免费解锁了解一下
            </router-link>
          </template>

        </template>

      </div>
    </model>


    <div class="operation">
      <div class="left">
        <div class="icon-group">
          <div class="operation-item">
            <template>
              <i class="iconfont icon-like" @click="toggleRecommend" v-if="!info.clickRecommend"></i>
              <i class="iconfont icon-like_active icon-active" v-else @click="toggleRecommend"></i>
            </template>
            <div class="operation-badge" v-if="info.planTotalRecommendCount>0">
              {{ info.planTotalRecommendCount > 99 ? '99+' : info.planTotalRecommendCount }}
            </div>
          </div>

          <div class="operation-item">
            <template>
              <i class="iconfont icon-collect" @click="toggleCollect" v-if="!info.clickCollect"></i>
              <i class="iconfont icon-collect_active icon-active" v-else @click="toggleCollect"></i>
            </template>
            <div class="operation-badge" v-if="info.planTotalCollectCount>0">
              {{ info.planTotalCollectCount > 99 ? '99+' : info.planTotalCollectCount }}
            </div>
          </div>


          <div class="operation-item">
            <template>
              <i class="iconfont icon-share" @click="visibles.share = true"></i>
            </template>
            <div class="operation-badge" v-if="info.planTotalPayCount>0">
              {{ info.planTotalPayCount > 99 ? '99+' : info.planTotalPayCount }}
            </div>
          </div>
        </div>
      </div>

      <div class="right">
        <div class="unlock-btn" v-if="!info.pay" @click="onClickUnlock">解锁下载
          <template v-if="info.planTotalPayCount">({{ info.planTotalPayCount }}次)</template>
          <img src="@/assets/images/download.svg" alt="">
        </div>
        <div class="unlock-btn has-unlock" v-else @click="$refs.hasUnlockedModel.toggleDialog()">
          点击下载(已解锁)
          <div class="operation-badge" v-if="info.planTotalPayCount>0" style="right: -20px;width: auto;padding: 0 8px;">
<!--            {{ info.planTotalPayCount > 99 ? '99+' : info.planTotalPayCount }}-->
            {{info.planTotalPayCount}} 次下载
          </div>
        </div>
      </div>
    </div>
    <plan-detail-vip-popup ref="planDetailVipPopup"/>
  </div>
</template>


------------------ script ------------------
<script>
import {mapGetters} from "vuex";
import {planListV2Api} from "../../../api/v2/planListV2Api";
import {taskV2Api} from "../../../api/v2/taskV2Api";
import {copyToClipBoard, sleep} from "../../../config/util";
import DownloadPlanModal from "./downloadPlanModal";
import model from '@/components/model/model'
import {user, scheme} from "@/api";
import PayTypeBottomSelect from "../../../components/biz/payTypeBottomSelect";
import {rechargeV2Api} from "../../../api/v2/rechargeV2Api";
import wxValidate from "../../../config/wx.validate";
import UnlockRecharge from "./unlockRecharge";
import {configV2Api} from "../../../api/v2/configV2Api";
import PlanDetailVipPopup from "../detail/components/planDetailVipPopup";
import {redirectV2Api} from "../../../api/v2/redirectV2Api";

export default {
  props: ['info'],
  mixins: [wxValidate],
  components: {PlanDetailVipPopup, UnlockRecharge, DownloadPlanModal, model, PayTypeBottomSelect},
  computed: {
    ...mapGetters(['userInfo', 'userVip', 'wallet'])
  },

  created() {
    this.loadRecommendAwardYanzhi()
  },


  watch: {
    userVip: {
      immediate: true,
      handler() {
        this.computeFinalPrice()
      }
    },

    info: {
      immediate: true,
      handler() {
        this.computeFinalPrice()
      }
    }
  },


  data() {
    return {
      visibles: {
        share: false
      },
      finalPrice: 0,
      shareOptions: [
        {name: '💰复制链接', icon: 'link', code: 'copyLink'},
        {name: '💰分享海报', icon: 'poster', code: 'sharePost'},
      ],
      config: {
        recommendAwardYanzhi: 0
      },
    }
  },


  methods: {

    async loadRecommendAwardYanzhi() {
      const res = await taskV2Api.findById(6);
      this.config.recommendAwardYanzhi = res.value;
    },


    selectShare(option) {
      const {code} = option;
      if (code === 'copyLink') {
        this.copy();
      } else if (code === 'sharePost') {
        return this.$router.push({name: 'caseDetailSharePost', params: {id: this.info.id}, query: {group: true}})
      }

      this.visibles.share = false;
    },


     copy() {
      const {info} = this
      const {userInfo} = this
      let url = window.location.href;
      if (this.userInfo.id) {
        if (window.location.href.indexOf('?') > -1) {
          url += '&shareUserId=' + userInfo.id;
        } else {
          url += '?shareUserId=' + userInfo.id;
        }
      }
      const redirectId = redirectV2Api.create(url)
      url = `https://www.001ppt.com/redirect?id=${redirectId}`

      const str = info.title + '  ' + url + '  '
      copyToClipBoard(str)
      this.$toast.success('复制链接成功');
    },


    async toggleRecommend() {
      const {info} = this
      const planListId = info.id
      await planListV2Api.toggleRecommend({planListId})
      const successMsg = info.clickRecommend ? '取消推荐成功' : '推荐成功'
      this.$toast.success(successMsg)
      this.$emit('action', {
        action: 'recommend'
      })
    },


    async toggleCollect() {
      const {info} = this
      const planListId = info.id
      await planListV2Api.toggleCollect({planListId})
      const successMsg = info.clickCollect ? '取消收藏成功' : '收藏成功'
      this.$toast.success(successMsg)
      this.$emit('action', {
        action: 'collect'
      })
    },


    async onClickUnlock() {
      if (!this.hasLogin) {
        return this.$store.commit('SHOW_LOGIN_DIALOG')
      }
      const [config] = await configV2Api.findByCodes(['IS_PLAN_LIST_ONLY_FOR_VIP'])
      if(config.value === 'Y'){
        if(this.userVip.vipRightId === '5'){
          return this.$refs.unlockModel.toggleDialog()
        }
        if(this.userVip.vipRightId === '4' && this.info.isMemberFree){
          return this.$refs.unlockModel.toggleDialog()
        }
        const vipRightId = this.info.isMemberFree? '4':'5'
        await this.$refs.planDetailVipPopup.show()
        await this.$refs.planDetailVipPopup.switchVipRight(vipRightId)
        return this.$toast.info(`该合集为${this.info.isMemberFree?'VIP':'俱乐部会员'}专享`)
      }
      this.$refs.unlockModel.toggleDialog()
    },


    async confirmUnlock() {
      try {
        this.$loading()
        const res = await scheme.unlockAddList({
          planListId: this.info.id,
          userId: this.userInfo.id
        })
        if (res.code !== 1) {
          return this.$toast.error(res.msg)
        }
        this.$loading.close()
        this.$toast.success('操作成功')
        await sleep(1000)
        this.$refs.unlockModel.hide()
        this.$refs.hasUnlockedModel.toggleDialog().then()
        this.$emit('action', {action: 'unlock'})
        this.$emit('unlock')
      } catch (e) {
        this.$toast.error(e.message)
      } finally {
        this.$loading.close()
      }
    },


    computeFinalPrice() {
      const userVip = this.userVip || {caseList: 10}
      const {info} = this
      if (!info || !info.downloadPrice) {
        return this.finalPrice = 0
      }
      this.finalPrice = userVip.caseList * info.downloadPrice / 10
    },


    showRecharge() {
      this.$refs.unlockModel.hide()
      this.$refs.payTypeBottomSelect.show()
    },

  },

}
</script>


------------------ styles ------------------
<style lang="less" scoped>
@import "./unlock.less";

.operation {
  position: fixed;
  bottom: 0;
  width: 100vw;
  background-color: #fff;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  z-index: 10;
  box-sizing: border-box;
}


.icon-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 81px;
}

.icon-group i {
  font-size: 19px;
  color: var(--text-color);
}

.icon-group i.icon-collect {
  font-size: 21px;
}

.icon-group i.icon-collect_active {
  font-size: 21px;
}

.icon-group i.icon-active {
  color: #e1494e;
}

.unlock-btn {
  padding: 8px 20px 8px 24px;
  background: #ee4a50;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-size: 12px;
  position: relative;
}

.unlock-btn.has-unlock {
  background: #151D36;
}

.unlock-btn img {
  height: 12px;
  width: 12px;
  margin-left: 4px;
}

.unlock-dialog-header {
  top: -30px;
  left: -22px;
}


.operation-item {
  position: relative;
  margin-right: 16px;
}


.operation-badge {
  position: absolute;
  right: -16px;
  top: -16px;
  background: #e44c4c;
  color: #fff;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-size: 12px;
  transform: scale(.6);
  font-weight: bolder;
}
</style>
