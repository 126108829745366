<template>
  <div class="unlock-recharge">
    <div class="desc" style="padding-top: 16px;padding-bottom: 8px" v-if="needRechargeMoney*10>0">您还需要再充值 &nbsp;<div class="red">
      {{ yanzhiFilter(needRechargeMoney * 10) }}严值(¥{{ moneyFilter(needRechargeMoney) }})
    </div>
    </div>
    <div class="recharge">
      <div class="recharge-item"
           :class="{active:rechargeMoney===needRechargeMoney}" @click="rechargeMoney=needRechargeMoney">
        <div class="recharge-price">¥{{ moneyFilter(needRechargeMoney) }}</div>
        <div class="recharge-yanzhi">得{{ yanzhiFilter(currRechargeMoneyFinalYanzhi) }}严值</div>
      </div>
      <div class="recharge-item" v-if="nextRechargeOption"
           :class="{active:rechargeMoney===nextRechargeOption.minPrice}"
           @click="rechargeMoney=nextRechargeOption.minPrice">
        <div class="recharge-price">¥{{ moneyFilter(nextRechargeOption.minPrice) }}</div>
        <div class="recharge-yanzhi">得{{
            yanzhiFilter(nextRechargeOption.minPrice * nextRechargeOption.rate)
          }}严值
        </div>
        <div class="recharge-give-yanzhi">多送{{ nextGiveYanzhi }}</div>
      </div>
    </div>

    <div class="confirm-btn" @click="$refs.payTypeBottomSelect.show()">确认充值解锁</div>
    <pay-type-bottom-select :auto-hide="true" ref="payTypeBottomSelect" @choose="startRecharge"/>
  </div>
</template>

<script>
import {rechargeRateV2Api} from "../../../api/v2/rechargeRateV2Api";
import PayTypeBottomSelect from "../../../components/biz/payTypeBottomSelect";
import {rechargeV2Api} from "../../../api/v2/rechargeV2Api";
import {processPay} from "../../../config/util";

export default {
  components: {PayTypeBottomSelect},
  props: ['needRechargeMoney','defaultRechargeMoney'],
  data() {
    return {
      rechargeOptions: [],
      rechargeMoney: ''
    }
  },


  watch: {
    needRechargeMoney: {
      immediate: true,
      handler() {
        this.rechargeMoney = this.needRechargeMoney
      }
    }
  },


  computed: {

    // 当前补差价方式最终获得的严值
    currRechargeMoneyFinalYanzhi() {
      const option = this.currRechargeOption
      const rate = (option ? option.rate : 10)
      return this.needRechargeMoney * rate
    },


    currRechargeOption() {
      let option = null
      for (const opt of this.rechargeOptions) {
        if (opt.minPrice > this.needRechargeMoney) {
          break
        }
        option = opt
      }
      return option
    },


    // 下一个充值选项
    nextRechargeOption() {
      if (this.defaultRechargeMoney) {
        return this.rechargeOptions.find(opt => opt.minPrice === this.defaultRechargeMoney)
      }

      const currOpt = this.currRechargeOption
      let currIdx = currOpt ? this.rechargeOptions.findIndex(opt => opt.minPrice === currOpt.minPrice) : -1
      for (let i = currIdx + 1; i < this.rechargeOptions.length; i++) {
        const opt = this.rechargeOptions[i]
        if (opt.rate > 10) {
          return opt
        }
      }
      return this.rechargeOptions[this.rechargeOptions.length - 1]
    },


    // 下一个充值选项赠送的严值
    nextGiveYanzhi() {
      const opt = this.nextRechargeOption
      return this.yanzhiFilter(opt.minPrice * (opt.rate - 10))
    }

  },


  async mounted() {
    this.rechargeOptions = await rechargeRateV2Api.getList()
  },


  methods: {

    async startRecharge(e) {
      let res
      const {payType, paySubType} = e

      try {
        this.$loading('请求中')
        const money = parseFloat(this.rechargeMoney + '').toFixed(2)
        res = await rechargeV2Api.startPayRecharge({money, payType, paySubType});
      }finally {
        this.$loading.close()
      }
      await processPay(payType,paySubType,JSON.stringify(res))
      this.$emit('paySuccess')
    }

  }

}
</script>

<style scoped lang="less">
@import "./unlock";

.recharge {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 16px;
}

.recharge-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100px;
  padding: 24px 0;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  margin: 0 12px;
  cursor: pointer;
  color: #d9d9d9;
  position: relative;

  .recharge-give-yanzhi {
    padding: 4px 8px;
    color: #fff;
    background: #e44c4c;
    border-radius: 4px;
    position: absolute;
    right: -8px;
    top: -8px;
    font-size: 12px;
  }

  .recharge-price {
    font-size: 22px;
    font-weight: bolder;
    padding-bottom: 8px;
  }

  .recharge-yanzhi {
    font-size: 14px;
  }

  &.active {
    border-color: #5fce81;

    .recharge-price {
      color: #151D36;
    }

    .recharge-yanzhi {
      color: #e44c4c;
    }
  }
}

.confirm-btn{
  margin-top: 32px;
}
</style>
